var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trucking-report-tool",class:_vm.isLogout ? 'logout-wraper pt-10' : '',style:(_vm.isLogout ? 'background-color: #F1F6FA !important;' : '')},[(!_vm.isError404)?_c('div',{staticClass:"trucking-report-inner-content"},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-4"},[_c('h2',[_vm._v("Trucking Report Tool ")]),(!_vm.isLogout)?_c('button',{staticClass:"btn-white",on:{"click":_vm.shareLink}},[_vm._v(" Share Link "),_c('input',{attrs:{"type":"hidden","id":"uniqueToken"},domProps:{"value":_vm.getUniqueTokenValue}})]):_vm._e()]),_c('div',{staticClass:"overlay-logout-loading",class:_vm.isLogout && _vm.$store.state.reports.isFirstLoading ? 'show' : ''},[(_vm.isLogout && _vm.$store.state.reports.isFirstLoading)?_c('div',{staticClass:"preloader"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#1A6D9E","indeterminate":""}})],1):_vm._e()]),_c('div',{staticClass:"overlay-loading",class:{
                    'show': _vm.getTruckingContainersLoading,
                    'from-logout': _vm.isLogout
                }},[(_vm.getTruckingContainersLoading)?_c('div',{staticClass:"preloader"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#1A6D9E","indeterminate":""}})],1):_vm._e()]),_c('v-data-table',{staticClass:"report-tool-table",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"empty-returned-filter d-flex justify-end align-center px-4 py-3"},[_c('div',{staticClass:"d-flex justify-end align-center"},[_c('p',{staticClass:"mb-0 mr-3 font-medium"},[_vm._v("Empty Returned Days")]),_c('v-text-field',{staticClass:"text-fields",attrs:{"placeholder":"Enter Days","outlined":"","hide-details":"auto","height":"40px"},on:{"input":_vm.onEmptyReturnedDaysChange},model:{value:(_vm.emptyReturnedDays),callback:function ($$v) {_vm.emptyReturnedDays=$$v},expression:"emptyReturnedDays"}})],1)])]},proxy:true},{key:"item.container_empty_notice_at",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.container_empty_notice_at)))])]}},{key:"item.return_appointment_date",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.return_appointment_date)))])]}},{key:"item.date_delivered_whse_at",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date_delivered_whse_at)))])]}},{key:"item.is_pre_pull",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.is_pre_pull === 0 || item.is_pre_pull === false ? 'No' : 'Yes'))])]}},{key:"item.terminal",fn:function(ref){
                var item = ref.item;
return [_c('p',{staticClass:"truncate-text"},[_vm._v(" "+_vm._s(item.terminal ? item.terminal.name : "-")+" ")])]}},{key:"item.is_with_per_diem_detention",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.is_with_per_diem_detention === 0 || item.is_with_per_diem_detention === false ? 'No' : 'Yes')+" ")])]}}],null,true)})],1):_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"flex-direction":"column","height":"100%"}},[_c('img',{staticClass:"mb-5",attrs:{"src":require("@/assets/icons/no-match-data.svg"),"alt":"","width":"45px","height":"45px"}}),_c('p',{staticClass:"font-medium",staticStyle:{"font-size":"32px"}},[_vm._v("404")]),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v("There's a problem visiting this page. ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }